<template>
    <section>
        <loader :dialog="loading"></loader>
        <floating-alert :message="alertMessage" :show="alertShow" :type="alertType"></floating-alert>
        <v-data-table :headers="headers" :items="transactions" class="elevation-0 pa-2 rounded-xl" :loading="loading"
            loading-text="Chargement en cours..." :search="search" no-results-text="Aucune transaction correpondante"
            :footer-props="{
                'items-per-page-all-text': 'Tout',
                'items-per-page-text': 'Lignes par page',
            }">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>Liste des transactions</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Rechercher"
                        hide-details></v-text-field>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogAddTransaction" max-width="400px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="red" dark class="mb-2 rounded-xl" v-bind="attrs" v-on="on">
                                <v-icon left>mdi-plus</v-icon> Ajouter
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>Ajouter une transaction
                                <!-- <v-spacer></v-spacer>
				<v-btn @click="dialogUsers = false" icon
				><v-icon>mdi-close-circle</v-icon></v-btn
				> -->
                            </v-card-title>
                            <v-card-text class="pt-3">
                                <v-form ref="form" v-model="valid" lazy-validation>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <v-select @click="showClients" v-model="editedItem.customerId"
                                                :items="clients" item-text="fullName" item-value="_id" chips dense
                                                label="Client" :rules="rules">
                                                <template #selection="{ item }">
                                                    <v-chip color="primary">{{ item.fullName }}</v-chip>
                                                </template>
                                            </v-select>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="12">
                                            <v-checkbox label="Cochez en cas d'inscription"
                                                v-model="editedItem.inscription"></v-checkbox>
                                        </v-col> -->
                                        <v-col cols="12" sm="12">
                                            <v-select @click="showSubscriptions" @change="updateTransactionDetails"
                                                item-value="_id" :items="subscriptions" chips dense label="Abonnement"
                                                :rules="rules" return-object>
                                                <template #item="{ item }">
                                                    {{ item.label }} - {{ item.type.toLowerCase() }} - {{ item.price }}
                                                    Fcfa
                                                </template>
                                                <template #selection="{ item }">
                                                    <v-chip color="primary">{{ item.label }}</v-chip>
                                                </template>
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                            <v-card-actions><v-btn text color="error"
                                    @click="dialogAddTransaction = false">Fermer</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" text color="primary" @click="save">Confirmer</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- SUBSCRIPTIONS LIST -->
                    <v-dialog v-model="subscriptionDialog" max-width="400px">
                        <v-card>
                            <v-card-title>Détails
                                <v-spacer></v-spacer>
                                <v-btn icon
                                    @click="subscriptionDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-icon>mdi-playlist-check</v-icon>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                        <v-list-item-title class="mb-2">{{ details.label }}</v-list-item-title>
                                        Prix:
                                        {{ details.price }} FCFA
                                        <br />
                                        Durée:
                                        {{ details.duration }} jours <br />
                                        Type: {{ details.type }} <br />
                                    </v-list-item-content>
                                </v-list-item>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="showDetails(item.subscription)" class="mr-2" color="primary">
                    mdi-information
                </v-icon>
            </template>
            <template v-slot:[`footer.page-text`]="items">
                {{ items.pageStart }} - {{ items.pageStop }} de
                {{ items.itemsLength }}
            </template>
            <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Actualiser </v-btn>
            </template>
        </v-data-table>
    </section>
</template>

<script>
import service from "@/services";
import Loader from "@/components/Loader.vue";
import FloatingAlert from "@/components/FloatingAlert.vue";
export default {
    components: { Loader, FloatingAlert },
    data() {
        return {
            valid: true,
            loadingTable: false,
            dialogAddTransaction: false,
            subscriptionDialog: false,
            search: "",
            details: {},
            transactions: [],
            clients: [],
            subscriptions: [],
            headers: [
                {
                    text: "Client",
                    value: "customer.fullName",
                },
                { text: "Opération", value: "operation" },
                { text: "Montant", value: "amount" },
                { text: "Méthode", value: "paymentMethod" },
                { text: "Date", value: "createdAt" },
                { text: "Actions", value: "actions", sortable: false, align: "center" },
            ],
            editedItem: {
                price: 0,
                subscriptionId: "",
                customerId: "",
                // inscription: false,
            },
            defaultItem: {
                price: 0,
                subscriptionId: "",
                customerId: "",
                // inscription: false,
            },
            alertMessage: "",
            alertShow: null,
            alertType: "",
            loading: false,
            selectedSubscription: null,
            rules: [(v) => !!v || "Veuillez remplir ce champ"],
        };
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    created() {
        this.initialize();
    },
    methods: {
        manageAlert(display, type, message) {
            this.alertShow = display;
            this.alertType = type;
            this.alertMessage = message;
        },
        updateTransactionDetails(subscription) {
            this.editedItem.subscriptionId = subscription._id;
            this.editedItem.price = subscription.price;
        },
        showDetails(subscriptionDetails) {
            this.details = subscriptionDetails;
            this.subscriptionDialog = true;
        },
        showClients() {
            this.loading = true;
            service
                .getClients()
                .then((response) => {
                    this.clients = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showSubscriptions() {
            this.loading = true;
            service
                .getSubscriptions()
                .then((response) => {
                    this.subscriptions = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        initialize() {
            this.loadingTable = true;
            service
                .getTransactions()
                .then((response) => {
                    this.transactions = response.data;
                    this.loadingTable = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loadingTable = false;
                });
        },
        showClients(item) {
            this.loading = true;
            service
                .getClients()
                .then((response) => {
                    this.clients = response.data;
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        save() {
            this.alertShow = false;
            if (this.$refs.form.validate()) {
                this.loading = true;
                service
                    .createPayment(
                        this.editedItem
                    )
                    .then((response) => {
                        this.loading = false;
                        this.dialogAddTransaction = false;
                        this.manageAlert(true, "success", "Paiement ajouté");
                        this.initialize();
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loading = false;
                        this.manageAlert(
                            true,
                            "error",
                            err.message
                        );
                    });
            }
        }
    },
};
</script>

<style></style>