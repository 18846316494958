<template>
	<div class="text-center">
		<loader :dialog="loading"></loader>
		<v-dialog persistent v-model="showDialog" width="500">
			<v-card>
				<v-card-title class="text-h5 grey lighten-2">
					Validation OTP
				</v-card-title>

				<v-card-text>
					<v-otp-input length="4" v-model="otpData"></v-otp-input>
					<v-alert type="info" dense>Merci de contacter l'administrateur pour recevoir le code!</v-alert>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="sendOtp">
						Valider
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import services from '../services';
	import Loader from './Loader.vue';
    export default {
        props: ['dialog'],
		components: { Loader },
        data(){
            return{
                otpData: "",
				loading: false
            }
        },
        computed: {
			showDialog() {
				if (this.dialog == true) {
					return true;
				} else {
					return false;
				}
			},
		},
        methods: {
            sendOtp(){
				this.loading = true;
                services.otp({otp: this.otpData}).then(res=>{
                    console.log(res);
                    window.location.reload();
                }).catch(err=>{
                    console.log(err);
                })
            }
        }
    }
</script>