<template>
	<div>
		<v-app-bar color="transparent" flat>
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>

			<QrCodeVue userId="" />

			<!-- <ModifyUserVue /> -->
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app class="pt-6 rounded-xl rounded-l-0">
			<v-list-item class="text-center mb-7" style="display: block">
				<v-list-item-avatar style="width: 60%; height: 80px">
					<v-img src="../assets/cez-logo.png"></v-img>
				</v-list-item-avatar>
			</v-list-item>
			<v-list dense>
				<v-list-item v-for="item in items" :key="item.title" link :to="item.path"
					active-class="red--text text--darken-1">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="text-subtitle-1">{{
							item.title
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<!-- <v-list-group
					no-action
					prepend-icon="mdi-weight-lifter"
					active-class="red--text text--darken-1"
				>
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title class="text-subtitle-1"
								>Séances</v-list-item-title
							>
						</v-list-item-content>
					</template>

<v-list-item v-for="({ title, path }, i) in sessionMenus" :key="i" link :to="path"
	active-class="red--text text--darken-1">
	<v-list-item-title>{{ title }}</v-list-item-title>
</v-list-item>
</v-list-group> -->
				<v-list-group no-action prepend-icon="mdi-dumbbell" active-class="red--text text--darken-1">
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title class="text-subtitle-1">Protocoles</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item v-for="({ title, path }, i) in protocols" :key="i" link :to="path"
						active-class="red--text text--darken-1">
						<v-list-item-title>{{ title }}</v-list-item-title>
					</v-list-item>
				</v-list-group>
				<v-list-group no-action prepend-icon="mdi-food" active-class="red--text text--darken-1">
					<template v-slot:activator>
						<v-list-item-content>
							<v-list-item-title class="text-subtitle-1">Repas</v-list-item-title>
						</v-list-item-content>
					</template>

					<v-list-item v-for="({ title, path }, i) in mealsMenus" :key="i" link :to="path"
						active-class="red--text text--darken-1">
						<v-list-item-title>{{ title }}</v-list-item-title>
					</v-list-item>
				</v-list-group>
				<v-list-item v-for="item in otherItems" :key="item.title" link :to="item.path"
					active-class="red--text text--darken-1">
					<v-list-item-icon>
						<v-icon>{{ item.icon }}</v-icon>
					</v-list-item-icon>

					<v-list-item-content>
						<v-list-item-title class="text-subtitle-1">{{
							item.title
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>

<script>
import QrCodeVue from "@/components/Admin/Dashboard/QrCode.vue";
import ModifyUserVue from "@/components/Admin/Dashboard/ModifyUser.vue";
export default {
	data() {
		return {
			drawer: null,
			items: [
				{
					title: "Tableau de bord",
					icon: "mdi-view-dashboard",
					path: "/admin",
				},
				{
					title: "Clients",
					icon: "mdi-account-group",
					path: "/clients",
				},
				{
					title: "Abonnements",
					icon: "mdi-playlist-check",
					path: "/abonnements",
				},
				{
					title: "Parrainages",
					icon: "mdi-family-tree",
					path: "/parrainages",
				},
				{
					title: "Coachs",
					icon: "mdi-account-tie",
					path: "/coachs",
				},
			],
			protocols: [
				{
					title: "Liste",
					path: "/protocoles/liste",
				},
				{
					title: "Exercices",
					path: "/protocoles/exercices",
				},
			],
			mealsMenus: [
				{
					title: "Liste",
					path: "/repas/liste",
				},
				{
					title: "Catégories",
					path: "/repas/categories",
				},
				{
					title: "Attributions",
					path: "/repas/attributions",
				},
			],
			otherItems: [
				{
					title: "Transactions",
					icon: "mdi-cash",
					path: "/transactions",
				},
				{
					title: "Assistance",
					icon: "mdi-hand-heart-outline",
					path: "/assistance",
				},
				{
					title: "FAQ",
					icon: "mdi-frequently-asked-questions",
					path: "/faq",
				},
			],
			/* sessionMenus: [
			{
				title: "Liste",
				path: "/seances/liste",
			},
			{
				title: "Attributions",
				path: "/seances/attributions",
			},
		], */
		};
	},
	components: {
		QrCodeVue,
		ModifyUserVue,
	},
};
</script>

<style></style>
