<template>
	<section>
		<loader :dialog="loaderDialog"></loader>
		<floating-alert
			:message="showAlertMessage"
			:show="showAlert"
			:type="showAlertColor"></floating-alert>
		<v-alert type="error" prominent v-if="accessDenied">
			<h5>Accès refusé</h5>
			Vous n'avez pas les autorisations pour avoir accès à ce contenu!
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="subscriptions"
			class="elevation-0 pa-2 rounded-xl"
			:loading="loading"
			loading-text="Chargement en cours..."
			:search="search"
			no-results-text="Aucun abonnement correpondant"
			:footer-props="{
				'items-per-page-all-text': 'Tout',
				'items-per-page-text': 'Lignes par page',
			}">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Liste des abonnements</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Rechercher"
						hide-details></v-text-field>
					<v-spacer></v-spacer>
					<v-dialog v-model="dialog" max-width="500px">
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								color="red"
								dark
								class="mb-2 rounded-xl"
								v-bind="attrs"
								v-on="on"
								v-if="!accessDenied">
								<v-icon left>mdi-plus</v-icon> Nouveau
							</v-btn>
						</template>
						<v-card>
							<v-card-title>
								<span class="text-h5">{{ formTitle }}</span>
							</v-card-title>

							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12" sm="6">
											<v-select
												:items="subscriptionTypes"
												label="Type"
												v-model="subscriptionPeriod"
												return-object
												item-text="type"></v-select>
										</v-col>
										<v-col cols="12" sm="6">
											<v-select
												:items="
													subscriptionPeriod.period
												"
												label="Intitulé"
												v-model="editedItem"
												return-object
												item-text="label"></v-select>
										</v-col>
										<v-col cols="12" sm="6">
											<v-text-field
												v-model="editedItem.price"
												label="Prix"
												disabled></v-text-field>
										</v-col>
										<v-col cols="12" sm="6">
											<v-text-field
												v-model="editedItem.duration"
												label="Durée"
												suffix="jours"
												disabled></v-text-field>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>

							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn color="red darken-1" text @click="close">
									Annuler
								</v-btn>
								<v-btn color="blue darken-1" text @click="save">
									Enregistrer
								</v-btn>
							</v-card-actions>
						</v-card>
					</v-dialog>

					<!-- CLIENTS LIST -->
					<v-dialog
						scrollable
						v-model="dialogUsers"
						max-width="500px">
						<v-card>
							<v-card-title>Liste des clients</v-card-title>
							<v-card-text>
								<client-list
									:clients="clients"
									:subscriptionId="subscriptionId"
									@close-clients-list="
										dialogUsers = false
									"></client-list>
							</v-card-text>
							<v-divider></v-divider>
							<v-card-actions
								><v-btn
									text
									color="error"
									@click="dialogUsers = false"
									>Fermer</v-btn
								></v-card-actions
							>
						</v-card>
					</v-dialog>

					<v-dialog v-model="dialogDelete" max-width="500px">
						<v-card>
							<v-card-title class="text-h5"
								>Êtes-vous sûrs de vouloir
								supprimer?</v-card-title
							>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn
									color="red darken-1"
									text
									@click="dialogDelete = false"
									>Annuler</v-btn
								>
								<v-btn
									color="blue darken-1"
									text
									@click="deleteItemConfirm"
									>OK</v-btn
								>
								<v-spacer></v-spacer>
							</v-card-actions>
						</v-card>
					</v-dialog>
				</v-toolbar>
			</template>
			<template v-slot:[`item.actions`]="{ item }">
				<v-icon small @click="showClients(item)" class="mr-2">
					mdi-account-plus
				</v-icon>
				<v-icon small class="mr-2" @click="editItem(item)">
					mdi-pencil
				</v-icon>
				<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
			</template>
			<template v-slot:[`footer.page-text`]="items">
				{{ items.pageStart }} - {{ items.pageStop }} de
				{{ items.itemsLength }}
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize"> Actualiser </v-btn>
			</template>
		</v-data-table>
	</section>
</template>

<script>
	import Loader from "@/components/Loader.vue";
	import service from "../services";
	import ClientList from "@/components/Admin/Subscriptions/ClientList.vue";
	import FloatingAlert from "@/components/FloatingAlert.vue";
	export default {
		components: { Loader, ClientList, FloatingAlert },
		data: () => ({
			search: "",
			accessDenied: false,
			showAlert: false,
			showAlertMessage: "",
			showAlertColor: "",
			dialog: false,
			dialogDelete: false,
			dialogUsers: false,
			loaderDialog: false,
			loading: false,
			headers: [
				{
					text: "Intitulé",
					value: "label",
				},
				{ text: "Prix", value: "price" },
				{ text: "Durée", value: "duration" },
				{ text: "Type", value: "type" },
				{ text: "Actions", value: "actions", sortable: false },
			],
			clients: [],
			subscriptionId: "",
			subscriptions: [],
			subscriptionPeriod: {},
			subscriptionTypes: [
				{
					type: "Classique",
					period: [
						{ label: "Mensuel", price: 10000, duration: 30 },
						{ label: "Trimestriel", price: 25000, duration: 90 },
						{ label: "Semestriel", price: 45000, duration: 180 },
						{ label: "Annuel", price: 85000, duration: 360 },
					],
				},
				{
					type: "Selfworkout",
					period: [
						{ label: "Mensuel", price: 15000, duration: 30 },
						{ label: "Trimestriel", price: 40000, duration: 90 },
						{ label: "Semestriel", price: 75000, duration: 180 },
						{ label: "Annuel", price: 150000, duration: 360 },
					],
				},
				{
					type: "Build muscle",
					period: [
						{ label: "Mensuel", price: 25000, duration: 30 },
						{ label: "Trimestriel", price: 65000, duration: 90 },
						{ label: "Semestriel", price: 1250000, duration: 180 },
						{ label: "Annuel", price: 240000, duration: 360 },
					],
				},
				{
					type: "Premium",
					period: [
						{ label: "Mensuel", price: 80000, duration: 30 },
						{ label: "Trimestriel", price: 220000, duration: 90 },
						{ label: "Semestriel", price: 420000, duration: 180 },
						{ label: "Annuel", price: 810000, duration: 360 },
					],
				},
			],
			editedIndex: -1,
			modifiedSubscriptionId: "",
			editedItem: {
				label: "",
				price: 0,
				duration: 0,
				type: "",
				_id: "",
			},
			defaultItem: {
				label: "",
				price: 0,
				duration: 0,
				type: "",
				_id: "",
			},
			e: {},
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Nouvel abonnement"
					: "Modification de l'abonnement";
			},
		},

		watch: {
			dialog(val) {
				val || this.close();
			},
			dialogDelete(val) {
				val || this.closeDelete();
			},
		},

		created() {
			this.initialize();
		},

		methods: {
			initialize() {
				this.loading = true;
				service
					.getSubscriptions()
					.then((response) => {
						this.subscriptions = response.data;
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.accessDenied = err ? true : false;
						this.loading = false;
					});
			},
			showClients(item) {
				this.loaderDialog = true;
				service
					.getClients()
					.then((response) => {
						this.subscriptionId = item._id;
						this.clients = response.data;
						this.dialogUsers = true;
						this.loaderDialog = false;
					})
					.catch((err) => {
						console.log(err);
					});
			},
			editItem(item) {
				this.editedIndex = this.subscriptions.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.modifiedSubscriptionId = item._id;
				this.dialog = true;
			},

			deleteItem(item) {
				this.editedIndex = this.subscriptions.indexOf(item);
				this.editedItem = Object.assign({}, item);
				this.dialogDelete = true;
			},

			deleteItemConfirm() {
				this.showAlert = false;
				this.loaderDialog = true;
				service
					.deleteSubscription(this.editedItem._id)
					.then((response) => {
						this.loaderDialog = false;
						this.closeDelete();
					})
					.catch((err) => {
						this.showAlertMessage = err.message;
						this.showAlertColor = "red";
						this.showAlert = true;
					});
			},

			close() {
				this.dialog = false;
				this.$nextTick(() => {
					this.editedItem = Object.assign({}, this.defaultItem);
					this.initialize();
					this.editedIndex = -1;
				});
			},

			closeDelete() {
				this.dialogDelete = false;
				this.$nextTick(() => {
					// this.editedItem = Object.assign({}, this.defaultItem);
					this.initialize();
					this.editedIndex = -1;
				});
			},

			save() {
				this.showAlert = false;
				if (this.editedIndex > -1) {
					this.editedItem.type = this.subscriptionPeriod.type;
					this.editedItem._id = this.modifiedSubscriptionId;
					this.loaderDialog = true;
					service
						.updateSubscription(
							this.editedItem._id,
							this.editedItem
						)
						.then((response) => {
							this.loaderDialog = false;
							this.close();
						})
						.catch((err) => {
							console.log(err);
						});
				} else {
					this.loaderDialog = true;
					this.editedItem.type = this.subscriptionPeriod.type;
					service
						.createSubscription(this.editedItem)
						.then((response) => {
							this.loaderDialog = false;
							this.close();
						})
						.catch((err) => {
							console.log(err);
							this.loaderDialog = false;
							this.showAlert = true;
							this.showAlertMessage = err
								? err.message
								: "Vérifiez votre connexion internet!";
							this.showAlertColor = "red";
						});
				}
			},
		},
	};
</script>

<style></style>
