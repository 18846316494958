<template>
	<section>
		<v-data-table
			:headers="headers"
			:items="presences"
			:items-per-page="5"
			:search="search"
			:loading="loading"
			sort-by="startAt"
			:sort-desc="true"
			loading-text="Chargement en cours..."
			class="elevation-10 pa-2 rounded-xl">
			<template v-slot:top>
				<v-toolbar flat>
					<v-toolbar-title>Liste des présences</v-toolbar-title>
					<v-divider class="mx-4" inset vertical></v-divider>
					<v-spacer></v-spacer>
					<v-text-field
						v-model="search"
						append-icon="mdi-magnify"
						label="Rechercher"
						hide-details></v-text-field>
					<!-- CLIENT DETAILS -->
					<v-dialog v-model="dialog" max-width="500px">
						<v-card>
							<v-card-title
								>Information du client
								<v-spacer></v-spacer>
								<v-btn icon @click="dialog = false"
									><v-icon>mdi-close-circle</v-icon></v-btn
								>
							</v-card-title>
							<v-card-text>
								Nom: {{ clientDetails.name }}<br />
								Téléphone: {{ clientDetails.phone }}<br />
								Age: {{ clientDetails.age }}<br />
								Abonnement:
								{{ clientDetails.subscription }} jours<br />
								Type d'abonnement: {{ clientDetails.type }}
								<br />
								Jours restants de l'abonnement:
								{{ clientDetails.subscriptionRemaining }} jours
							</v-card-text>
						</v-card></v-dialog
					>
				</v-toolbar>
			</template>
			<template v-slot:no-data>
				<v-btn color="primary" @click="initialize"> Actualiser </v-btn>
			</template>
		</v-data-table>
	</section>
</template>

<script>
	import server from "@/config/address";
	import services from "@/services";
	import io from "socket.io-client";
	import moment from "moment";
	export default {
		data: () => ({
			socket: io(`${server.url}`),
			dialog: false,
			clientDetails: {},
			loading: false,
			search: "",
			headers: [
				{
					text: "Noms",
					align: "start",
					value: "customer.fullName",
				},
				{ text: "Arrivée", value: "startAt" },
			],
			presences: [],
		}),

		created() {
			this.initialize();
		},
		mounted() {
			// this.socket.emit("presence", { name: "test" });
			this.socket.on("client-details", (data) => {
				this.clientDetails = data;
				this.dialog = true;
				this.presences.unshift({
					customer: { fullName: data.name },
					startAt: moment().format("YYYY-MM-DD HH:mm"),
				});
			});
		},
		methods: {
			initialize() {
				this.loading = true;
				services
					.getPresences()
					.then((response) => {
						this.presences = response.data;
						this.loading = false;
					})
					.catch((err) => {
						console.log(err);
						this.loading = false;
					});
			},
		},
	};
</script>

<style></style>
