import { render, staticRenderFns } from "./Otp.vue?vue&type=template&id=1d9e3707&"
import script from "./Otp.vue?vue&type=script&lang=js&"
export * from "./Otp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports