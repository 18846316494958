<template>
	<v-card>
		<loader :dialog="loading"></loader>
		<v-card-title>Liste des clients </v-card-title>
		<v-card-text>
			<v-text-field
				label="Rechercher"
				prepend-inner-icon="mdi-magnify"
				clearable
				v-model="search"
			></v-text-field>
			<v-list>
				<v-list-item-group
					v-model="customerProtocols"
					active-class="red--text text--darken-3"
					multiple
				>
					<template v-for="(client, index) in filteredItems">
						<v-list-item :key="'A' + index" :value="client._id">
							<v-list-item-avatar>
								<v-icon>mdi-account</v-icon>
							</v-list-item-avatar>

							<v-list-item-content>
								<v-list-item-title class="mb-2">{{
									client.fullName
								}}</v-list-item-title>
								Poids:
								{{ client.weight }} Kg
								<br />
								Taille:
								{{ client.height }} m
							</v-list-item-content>
						</v-list-item>
						<v-divider
							v-if="index < filteredItems.length - 1"
							:key="index"
						></v-divider>
					</template>
				</v-list-item-group>
			</v-list>
		</v-card-text>
		<v-card-actions
			><v-btn text color="error" @click="$emit('close-client-dialog')"
				>Fermer</v-btn
			>
			<v-spacer></v-spacer>
			<v-btn text color="primary" @click="singleProtocolAttribution"
				>Confirmer</v-btn
			>
		</v-card-actions>
	</v-card>
</template>

<script>
import service from "@/services";
import Loader from "@/components/Loader.vue";
export default {
	components: { Loader },
	props: ["protocolId"],
	data() {
		return {
			loading: false,
			search: "",
			customerProtocols: [],
			clients: [],
		};
	},
	computed: {
		filteredItems() {
			return this.clients.filter((item) => {
				return item.fullName
					.toLowerCase()
					.includes(this.search.toLowerCase());
			});
		},
	},
	mounted() {
		this.showClients();
	},
	methods: {
		showClients() {
			this.loading = true;
			service
				.getClients()
				.then((response) => {
					this.clients = response.data;
					// this.dialogUsers = true;
					this.loading = false;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		singleProtocolAttribution() {
			this.loading = true;
			service
				.singleProtocolAttribution(
					this.protocolId,
					this.customerProtocols
				)
				.then((response) => {
					console.log(response);
					this.loading = false;
					this.customerProtocols = [];
					this.$emit("successful-attribution");
				})
				.catch((err) => {
					console.log(err);
					this.loading = false;
				});
		},
	},
};
</script>

<style></style>
